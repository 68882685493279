
import { defineComponent, ref, onBeforeUnmount } from 'vue'
import { Input } from '@progress/kendo-vue-inputs'
import { DropDownList } from '@progress/kendo-vue-dropdowns'

export default defineComponent({
  components: {
    Input,
    DropDownList
  },
  setup() {
    const success = ref(false)
    const Id = ['員工', '訪客', '廠商']
    const handleSubmit = e => {
      e.preventDefault()
      success.value = true
      setTimeout(() => {
        success.value = false
      }, 3000)
    }

    document.body.classList.add('light') // for 下拉元件顯示樣式
    onBeforeUnmount(() => {
      document.body.classList.remove('light')
    })
    return { Id, success, handleSubmit }
  }
})
