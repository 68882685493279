<template>
  <div id="page-verification">
    <div class="pixis_logo">
      <svg
        id="Group_1001"
        data-name="Group 1001"
        xmlns="http://www.w3.org/2000/svg"
        width="106.456"
        height="29.528"
        viewBox="0 0 106.456 29.528"
      >
        <g id="Group_164" data-name="Group 164">
          <path
            id="Path_10"
            data-name="Path 10"
            d="M149.376,65.817a2.072,2.072,0,0,0-.542-1.358,1.963,1.963,0,0,0-2.776-.068c-.024.022-.046.046-.069.068L122.152,88.164a1.941,1.941,0,0,0-.541,1.354,2.071,2.071,0,0,0,.541,1.355,1.945,1.945,0,0,0,1.355.541,2.073,2.073,0,0,0,1.355-.541l23.7-23.7a1.389,1.389,0,0,0,.816-1.355"
            transform="translate(-82.808 -62.972)"
            fill="#fff"
          />
          <path
            id="Path_11"
            data-name="Path 11"
            d="M133.665,72.972,125,64.3a1.964,1.964,0,0,0-2.776-.068c-.023.022-.046.045-.068.068a1.939,1.939,0,0,0-.541,1.353,2.071,2.071,0,0,0,.541,1.355l8.668,8.667c.947-.816,1.9-1.761,2.843-2.71"
            transform="translate(-82.808 -62.948)"
            fill="#fff"
          />
          <path
            id="Path_12"
            data-name="Path 12"
            d="M151.691,91.006l-8.8-8.8-2.844,2.845,8.8,8.8a1.943,1.943,0,0,0,1.354.541,2.071,2.071,0,0,0,1.355-.541A1.945,1.945,0,0,0,152.1,92.5a1.365,1.365,0,0,0-.406-1.49"
            transform="translate(-85.662 -65.813)"
            fill="#fff"
          />
          <path
            id="Path_13"
            data-name="Path 13"
            d="M112.192,62.813a2.428,2.428,0,0,0-2.438,2.419V89.9a2.438,2.438,0,0,0,4.877,0V65.115a2.4,2.4,0,0,0-2.439-2.3"
            transform="translate(-80.973 -62.811)"
            fill="#fff"
          />
          <path
            id="Path_14"
            data-name="Path 14"
            d="M91.19,62.813H78.139a2.438,2.438,0,0,0,0,4.876H91.19a3.738,3.738,0,0,1,3.792,3.519,3.575,3.575,0,0,1-.947,2.573,3.7,3.7,0,0,1-2.574,1.084H77.1V89.9a2.4,2.4,0,0,0,2.438,2.3,2.429,2.429,0,0,0,2.438-2.419V79.472h9.616a8.01,8.01,0,0,0,6.094-2.71,8.447,8.447,0,0,0,2.3-6.366,8.708,8.708,0,0,0-8.8-7.584"
            transform="translate(-75.701 -62.811)"
            fill="#fff"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M163.149,62.813a2.428,2.428,0,0,0-2.437,2.419V89.9a2.438,2.438,0,1,0,4.877,0V65.115a2.4,2.4,0,0,0-2.439-2.3"
            transform="translate(-88.861 -62.811)"
            fill="#fff"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M188.288,75.138H180.07a3.737,3.737,0,0,1-2.845-6.365,3.777,3.777,0,0,1,2.71-1.084h13.186a2.852,2.852,0,0,0,1.755-.679,2.575,2.575,0,0,0,.678-1.761,2.428,2.428,0,0,0-2.418-2.437H179.93a8.533,8.533,0,0,0-.2,17.065h8.693a3.8,3.8,0,0,1,.407,7.584H175.237a2.438,2.438,0,0,0,0,4.877h13.186a8.5,8.5,0,0,0,8.533-8.463v-.07a8.611,8.611,0,0,0-2.574-6.094,8.3,8.3,0,0,0-6.094-2.574Z"
            transform="translate(-90.5 -62.811)"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
    <div class="background">
      <video autoplay muted loop id="myVideo">
        <source src="static/02_scroll_loop.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </div>
    <div class="translate-component">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.997"
        height="17.996"
        viewBox="0 0 17.997 17.996"
      >
        <path
          id="Icon_awesome-globe-africa"
          data-name="Icon awesome-globe-africa"
          d="M12,3a9,9,0,1,0,9,9v0A9,9,0,0,0,12,3Zm5.805,7.818v.252a.56.56,0,0,1-.311.5l-.559.28a.562.562,0,0,1-.563-.035l-.661-.441a.561.561,0,0,0-.489-.065l-.1.032a.562.562,0,0,0-.29.845l.48.72a.56.56,0,0,0,.467.251h.3a.563.563,0,0,1,.563.563v.411a.563.563,0,0,1-.113.338l-.68.907a.562.562,0,0,0-.1.233l-.156.828a.561.561,0,0,1-.172.311,5.81,5.81,0,0,0-.908,1.059l-.473.709a1.007,1.007,0,0,1-1.738-.108,2.862,2.862,0,0,1-.3-1.281V16.045a.563.563,0,0,0-.563-.563H10.5A1.982,1.982,0,0,1,8.519,13.5v-.509a1.983,1.983,0,0,1,.793-1.585l1-.749a1.977,1.977,0,0,1,1.189-.4h.032a1.987,1.987,0,0,1,.886.209l.534.267a.564.564,0,0,0,.43.031L15.1,10.19a.563.563,0,0,0-.177-1.1h-.366a.562.562,0,0,1-.4-.165l-.251-.251a.559.559,0,0,0-.4-.165H10.242a.563.563,0,0,1-.563-.563V7.794a.564.564,0,0,1,.426-.545l.526-.131a.566.566,0,0,0,.332-.234l.293-.44a.561.561,0,0,1,.467-.25H12.6a.563.563,0,0,0,.562-.563V4.844a7.269,7.269,0,0,1,5.872,5.414h-.669A.563.563,0,0,0,17.808,10.82Z"
          transform="translate(-3.004 -3.002)"
          fill="#171a1d"
        />
      </svg>
      <span>English</span>
    </div>
    <div class="verification-wrapper">
      <div class="verification-card">
        <div class="title">
          <h1>PIXIS 驗證</h1>
        </div>
        <div class="input-group">
          <form class="k-form" @submit="handleSubmit">
            <div class="input-item">
              <Input :label="'使用者帳號'" :required="true" />
            </div>
            <div class="input-item">
              <Input :label="'密碼'" type="password" :required="true" />
            </div>
            <div class="input-item dropdown">
              <DropDownList
                :data-items="Id"
                :default-item="'登入身分'"
                :style="{ width: '100%' }"
              />
            </div>
            <div class="input-item">
              <Input :label="'員工代碼'" />
            </div>

            <button>
              <input type="submit" class="k-button" value="送出" />
            </button>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="success"
      class="alert alert-success"
      :style="{ position: 'absolute' }"
    >
      Form submitted!
    </div>
    <div class="version">
      <span>PIXIS © 2021 v9.0.0.0</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeUnmount } from 'vue'
import { Input } from '@progress/kendo-vue-inputs'
import { DropDownList } from '@progress/kendo-vue-dropdowns'

export default defineComponent({
  components: {
    Input,
    DropDownList
  },
  setup() {
    const success = ref(false)
    const Id = ['員工', '訪客', '廠商']
    const handleSubmit = e => {
      e.preventDefault()
      success.value = true
      setTimeout(() => {
        success.value = false
      }, 3000)
    }

    document.body.classList.add('light') // for 下拉元件顯示樣式
    onBeforeUnmount(() => {
      document.body.classList.remove('light')
    })
    return { Id, success, handleSubmit }
  }
})
</script>

<style lang="scss" scoped></style>
